import { FaTimes } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';

function NortKerala() {
   const [packages, setPackages] = useState([]);
   const [formData, setFormData] = useState({
     name: '',
     email: '',
     message: '',
     packageName: '',
     contactNumber: '',
     adults: '',
     children: '',
     startDate: '', // Rename pickupDate to startDate
     endDate: '', // Add endDate field
     AdultPrice:'',
     ChildPrice:'',
     TotalPrice: 0
   });
   const [showPopup, setShowPopup] = useState(false);

   useEffect(() => {
    if (!isNaN(formData.adults) && !isNaN(formData.children)) {
      const adultPrice = parseFloat(formData.AdultPrice);
      const childPrice = parseFloat(formData.ChildPrice);
      const adults = parseInt(formData.adults);
      const children = parseInt(formData.children);
      const totalPrice = adultPrice * adults + childPrice * children;
      setFormData(prevState => ({ ...prevState, TotalPrice: totalPrice }));
    }
  }, [formData.adults, formData.children, formData.AdultPrice, formData.ChildPrice]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cttapi.jinskadamthodu.com/estuariesholidays/api/v1/view.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const southKeralaData = data.filter(item => item.main_pkg === "NORTH KERALA");
        setPackages(southKeralaData);
        data.forEach(item => {
          const responseadultPrice = item.adult_price;
          const responsechildrenPrice = item.children_price;
          console.log(`Adult Price: ${responseadultPrice}, Child Price: ${responsechildrenPrice}`);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

const handleInputChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('https://cttapi.jinskadamthodu.com/estuariesholidays/api/v1/save_data.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    console.log('Form submitted successfully');
    setFormData({
      name: '',
      email: '',
      message: '',
      packageName: '',
      contactNumber: '',
      adults: '',
      children: '',
      startDate: '', // Reset startDate to empty string
      endDate: '', // Reset endDate to empty string
      TotalPrice: 0
    });
    setShowPopup(false);
  } catch (error) {
    console.error('Error submitting form:', error);
  }
};

const closepopUp = () => {
  setFormData({
    name: '',
    email: '',
    message: '',
    packageName: '',
    contactNumber: '',
    adults: '',
    children: '',
    startDate: '', // Reset startDate to empty string
    endDate: '', // Reset endDate to empty string
    TotalPrice: 0
  });
  setShowPopup(false)
}

const handleBookNowClick = (pkg) => {
  setShowPopup(true);
  setFormData({ ...formData, packageName: pkg.pkg_name, AdultPrice: pkg.adult_price, ChildPrice: pkg.children_price });
};

const calculateTotalDays = () => {
  const start = new Date(formData.startDate);
  const end = new Date(formData.endDate);
  const diffTime = Math.abs(end - start);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

return (
  <div className="flex justify-center items-center my-2 lg:my-10 w-full mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full max-w-6xl p-10 sm:p-12 md:p-24 lg:p-5">
      {packages.map((pkg, index) => (
        <div key={index} className="bg-white shadow-md overflow-hidden hover:shadow-lg transition duration-300 transform hover:-translate-y-1 hover:scale-105 rounded-lg">
          <img src={pkg.image_url} alt={`Image for ${pkg.pkg_name}`} className="w-full object-cover" />
          <div className="p-4">
            <p className="text-lg font-bold mb-2 text-[#868686]">{pkg.pkg_name}</p>
            <p className="text-gray-600">{pkg.main_pkg}</p>
            <div className='flex justify-between'>
              <p>Adult</p>
              <p className="text-gray-600">{pkg.adult_price}</p>
            </div>
            <div className='flex justify-between'>
              <p>Kids</p>
              <p className="text-gray-600">{pkg.children_price}</p>
            </div>
            <div className='flex justify-between'>
              <p className="text-gray-600 mt-2">${pkg.price}</p>
              <button className="text-gray-600 mt-2 bg-slate-300 hover:bg-slate-200 p-1 rounded-sm text-sm" onClick={() => handleBookNowClick(pkg)}>Book Now</button>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30">
          <div className="bg-white p-6 rounded shadow-lg w-[90%] md:w-[70%] lg:w-[60%] xl:w-[40%] h-[50%] overflow-x-scroll">
            <div className='flex w-full justify-between'>
              <h2 className="text-lg font-semibold mb-4">Book with us</h2>
              <span className=' bg-slate-400 p-1 h-6 rounded-full mt-1 cursor-pointer hover:animate-spin' onClick={closepopUp}><FaTimes /></span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Name</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactNumber">Contact Number</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="tel" id="contactNumber" name="contactNumber" value={formData.contactNumber} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="adults">Number of Adults</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" id="adults" name="adults" value={formData.adults} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="children">Number of Children</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" id="children" name="children" value={formData.children} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startDate">Start Date</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" id="startDate" name="startDate" value={formData.startDate} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endDate">End Date</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" id="endDate" name="endDate" value={formData.endDate} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
                <textarea className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" name="message" value={formData.message} onChange={handleInputChange} />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="packageName">Package Name</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="packageName" name="packageName" value={formData.packageName} readOnly />
              </div>
               <p className="text-gray-700 font-bold mb-2">Total Price: ${(formData.TotalPrice * calculateTotalDays()).toFixed(2)}</p>
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  </div>
)
}

export default NortKerala;
