import { FaTimes } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';


function Ladakh() {

  const [packageprice,setPackagePrice]=useState()
 
// Razoorpay function





console.log(typeof(packageprice),'hhhh')


const options = {
  key: 'rzp_test_R6sZrZSxeijUeG',
  amount: '20000',
  // amount: packageprice, //  = INR 1
  name: 'Acme shop',
  description: 'some description',
  image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
  handler: function(response) {
      alert(response.razorpay_payment_id);
  },
  prefill: {
      name: 'Gaurav',
      contact: '9999999999',
      email: 'demo@demo.com'
  },
  notes: {
      address: 'some address'
  },
  theme: {
      color: 'blue',
      hide_topbar: false
  }
};

const openPayModal = () => {
  var rzp1 = new window.Razorpay(options);
  rzp1.open();
};
useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  script.async = true;
  document.body.appendChild(script);
}, []);
// end razoorpay function








   const [packages, setPackages] = useState([]);
   const [formData, setFormData] = useState({
     name: '',
     email: '',
     message: '',
     packageName: '', // Add packageName to formData
     contactNumber: '', // Add contactNumber to formData
     adults: '', // Add adults to formData
     children: '', // Add children to formData
     pickupDate: '' // Add pickupDate to formData
   });
   const [showPopup, setShowPopup] = useState(false); // Define showPopup state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cttapi.jinskadamthodu.com/estuariesholidays/api/v1/view.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Filter data for pkg_name "SOUTH KERALA"
        const southKeralaData = data.filter(item => item.main_pkg === "LADAKH");
        

        // Set packages state with filtered data
        setPackages(southKeralaData);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run effect only once on component mount


// pop-up form
const handleInputChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    // Make API call to submit form data
    const response = await fetch('https://cttapi.jinskadamthodu.com/estuariesholidays/api/v1/save_data.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Handle success
    console.log('Form submitted successfully');
    // Reset form data
    setFormData({
      name: '',
      email: '',
      message: '',
      packageName: '', // Reset packageName
      contactNumber: '', // Reset contactNumber
      adults: '', // Reset adults
      children: '', // Reset children
      pickupDate: '' // Reset pickupDate
    });
    // Close the popup after form submission
    setShowPopup(false);
  } catch (error) {
    console.error('Error submitting form:', error);
    // Handle error
  }
};

const closepopUp = ()=>{
  setFormData({
    name: '',
    email: '',
    message: '',
    packageName: '', // Reset packageName
    contactNumber: '', // Reset contactNumber
    adults: '', // Reset adults
    children: '', // Reset children
    pickupDate: '' // Reset pickupDate
  });
  setShowPopup(false)

}

// end po-up form

// get current date
const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  // Append 0 before month and day if they are single digits
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  return `${year}-${month}-${day}`;
};
// end get current date 

  return (
    <div className="flex justify-center items-center my-2 lg:my-10 w-full mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full max-w-6xl p-10 sm:p-12 md:p-24 lg:p-5">
      {packages.map((pkg, index) => (
        <div key={index} className="bg-white shadow-md overflow-hidden hover:shadow-lg transition duration-300 transform hover:-translate-y-1 hover:scale-105 rounded-lg">
          <img src={pkg.image_url} alt={`Image for ${pkg.pkg_name}`} className="w-full object-cover" />
          <div className="p-4">
            <p className="text-lg font-bold mb-2 text-[#868686]">{pkg.pkg_name}</p>
            <p className="text-gray-600">{pkg.main_pkg}</p>
            <div className='flex justify-between'>
              
              <p className="text-gray-600 mt-2">{pkg.price}</p>
              <button className="text-gray-600 mt-2 bg-slate-300 hover:bg-slate-200 p-1 rounded-sm text-sm" onClick={() => { setShowPopup(true); setFormData({ ...formData, packageName: pkg.pkg_name }); setPackagePrice({packageprice:pkg.price}) }}>Book Now</button>
              
            
            </div>
</div>
        </div>
      ))}
    </div>

{/* popup */}
<div>
     
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30">
          <div className="bg-white p-6 rounded shadow-lg w-[90%] md:w-[70%] lg:w-[60%] xl:w-[40%] h-[50%] overflow-x-scroll">
            
            
            <div className='flex w-full justify-between'>
            <h2 className="text-lg font-semibold mb-4">Book with us</h2>
            <span className=' bg-slate-400 p-1 h-6 rounded-full mt-1 cursor-pointer hover:animate-spin' onClick={closepopUp}><FaTimes /></span>
            </div>


            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Name</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactNumber">Contact Number</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="tel" id="contactNumber" name="contactNumber" value={formData.contactNumber} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="adults">Number of Adults</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" id="adults" name="adults" value={formData.adults} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="children">Number of Children</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" id="children" name="children" value={formData.children} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pickupDate">Pickup Date</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" id="pickupDate" name="pickupDate" min={getCurrentDate()} value={formData.pickupDate} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
                <textarea className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="message" name="message" value={formData.message} onChange={handleInputChange} />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="packageName">Package Name</label>
                <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="packageName" name="packageName" value={formData.packageName} readOnly />
              </div>
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
            </form>
           
          </div>
        </div>
      )}
    </div>
{/* end popup */}
<button onClick={openPayModal}>Pay with Razorpay</button>
  </div>
)
}

export default Ladakh;
