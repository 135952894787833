import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import '../src/App.css'
import OneDayPackage from './components/OneDayPackage';
import TwoDayPackage from './components/TwoDayPackage';
import HouseBoat from './components/HouseBoat';
import HimachalTourism from './components/HimachalTourism';
import Northkerala from './components/NorthKerala';
import Ladakh from './components/Ladakh';
import DelhiAgra from './components/DelhiAgra';
import Goa from './components/Goa';
import International from './components/International';
import BannerSlider from './components/BannerSlider';
import Counter from './components/StopValue';
import { useState } from 'react';
import logo from './assets/logo2.png'
import { FaWhatsapp } from 'react-icons/fa';
import atcm from './assets/ATCM-LOGO.png'
import Footer from './components/Footer';
import ThreeDayPackage from './components/ThreeDayPackage';
import { useEffect } from 'react';




function App() {


  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerColor, setHeaderColor] = useState('transparent');
  const [headerShadow, setHeaderShadow] = useState(false);


  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  
  // Tabswitch
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

// end Tab switch


useEffect(() => {
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    // Change header color and shadow based on scroll position
    if (position > 600) {
      setHeaderColor('#3c82f5'); // Glass effect color
      setHeaderShadow(true); // Add shadow
    } else {
      setHeaderColor('transparent'); // Change to your default color
      setHeaderShadow(false); // Remove shadow
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);






// whatsap function
const openWhatsApp = () => {
  // Phone number with country code
  const phoneNumber = '9746027227';
  // Message to be sent
  const message = 'Hello, I would like to chat with you!';
  // Encode message for URL
  const encodedMessage = encodeURIComponent(message);
  // WhatsApp API link
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  // Open link in new tab
  window.open(whatsappLink, '_blank');
};
// end whatsap function











  return (

    <div>




        <Router>

        <div className="flex justify-center">
  {/* <div style={{ backgroundColor: headerColor, boxShadow: headerShadow ? '0 2px 4px rgba(0,0,0,0.1)' : 'none' }} className='fixed h-18 md:h-32 z-50 flex justify-between items-center w-[92%] lg:w-[89%] rounded-md px-2'> */}
  <div className={`fixed top-3 h-18 md:h-32 z-50 flex justify-between items-center w-[92%] lg:w-[89%] px-2 my-4 ${scrollPosition > 600 ? 'border-none' : 'border-t border-white'}`}>
    {/* Your content */}
    <div className='lg:mt-16 mt-2'>
      <img src={logo} className='w-32 my-2 lg:mt-0 rounded-full' />
    </div>
    <div>
    <div className=" p-1 rounded-md">
         <button onClick={toggleDrawer} className="focus:outline-none block lg:hidden rounded-full p-1" style={{ backgroundColor: headerColor, boxShadow: headerShadow ? '0 2px 4px rgba(0,0,0,0.1)' : 'none' }}>
           <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="white">
             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
           </svg>
         </button>
         <ul className=" w-full hidden lg:flex rounded-lg p-2 shadow-xl" style={{ backgroundColor: headerColor, boxShadow: headerShadow ? '0 2px 4px rgba(0,0,0,0.1)' : 'none' }}>
       <li onClick={()=>{setActiveTab('')}}>
           <Link to="/booknow" className=" text-white cursor-pointer text-lg  lg:px-5 md:px-3 p-1 rounded-sm font-semibold">One Day Package</Link>
         </li>
         <li onClick={()=>{setActiveTab('')}}>
           <Link to="/TwoDayPackage" className=" text-white cursor-pointer text-lg lg:px-5 md:px-3 p-1 rounded-sm font-semibold">Two Day Package</Link>
         </li>
         <li onClick={()=>{setActiveTab('')}}>
           <Link to="/ThreeDayPackage" className=" text-white cursor-pointer text-lg lg:px-5 md:px-3 p-1 rounded-sm font-semibold">Three Day Package</Link>
         </li>
       </ul>

  </div>
 
       
      
 
       
 
       {isOpen && (
   <div className="fixed top-0 left-0 h-60 w-full justify-end">
     <div className="bg-white/80 rounded-md shadow-lg backdrop-blur-sm w-[60%] lg:w-[30%] z-50 float-right">
       <div className="p-4">
         <button onClick={toggleDrawer} className="focus:outline-none float-end">
         <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#012e7b">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
 </svg>
 
         </button>
       </div>
       
       <div className="flex flex-col items-center justify-center mt-[-35px]">
         <ul className="text-[#012e7b] text-md font-semibold">
          
 
         <li className="my-4  rounded-sm">
             <Link to="/booknow" onClick={toggleDrawer}>One Day Package</Link>
           </li>
           <li className="my-4  rounded-sm">
             <Link to="/TwoDayPackage" onClick={toggleDrawer} >Two Day Package</Link>
           </li>
           <li className="my-4 rounded-sm">
             <Link to="/ThreeDayPackage" onClick={toggleDrawer}>Three Day Package</Link>
           </li>
          
         </ul>
       </div>
     </div>
   </div>
 )}
    </div>
  </div>
</div>
       
      
      <div className="fixed top-5 left-0 right-0 z-20 flex justify-center">
   <div className="w-full lg:w-[95%] lg:rounded-md mx-2 rounded-md">
   <nav className="flex items-center justify-between py-4 px-6 text-white/60 font-semibold">
  
   
  
       
 
     
 
     </nav>
   </div>
 </div>
 <BannerSlider/>
 
 {/* long tab */}
 <div className="flex justify-center items-center">
   <div className="w-full lg:w-[90%]  bg-white/40 backdrop-blur-xl h-24 lg:rounded-md shadow-md mt-[-3rem] z-10 flex justify-center items-center rounded-md">
     <div className="flex justify-center gap-3 sm:gap-10">
       <div className='flex-column lg:flex'>
       <Counter stopValue={20} /><h3 className=' mt-0 xl:mt-4 lg:mt-1.5 text-center lg:text-2xl text-[#1d76bf] font-bold'>Packages</h3>
       </div>
       <hr className='h-16 w-[1px] bg-[#1d77bf]'/>
       <div className='flex-column lg:flex'>
       <Counter stopValue={40} /><h3 className=' mt-0 xl:mt-4 lg:mt-1.5 text-center lg:text-2xl text-[#1d76bf] font-bold'>Destinations</h3>
       </div>
       <hr className='h-16 w-[1px] bg-[#1d77bf]'/>
       <div className='flex-column lg:flex'>
       <Counter stopValue={100} /><h3 className=' mt-0 xl:mt-4 lg:mt-1.5 text-center lg:text-2xl text-[#1d76bf] font-bold'>Clients</h3>
       </div>
       
       
     </div>
   </div>
 </div>
 
 
 {/* end long tab */}

 <div className='md:flex justify-between mx-auto w-full lg:w-[80%] items-center p-4'>
  <div className='lg:w-[50%]'>
    <img src={atcm} className=''/>
  </div>

<div className='lg:w-[50%] text-center md:text-left'>
    <span className='font-semibold text-xl text-[#1d76bf]'>Exclusive Tour Package Offer for Delegates of the 46th Antarctic Treaty Consultative Meeting.</span><br/><br/>
<span className=''>
<b className='text-[#1d76bf] text-xl'>Estuaries Holidays</b> thrilled to present a special tour package exclusively designed for attendees of the upcoming 46th Antarctic Treaty Consultative Meeting. Our package offers a unique opportunity to explore the stunning landscapes of Kerala while enjoying top-notch accommodations and exceptional service. Don't miss out on this amazing opportunity to make the most of your time in Kerala. 
Contact us today to book your spot and secure this exclusive delegate rate. 
We guarantee an unforgettable experience that will exceed your expectations.
</span>
  </div>
 </div>
 
 
 
     <button
       onClick={openWhatsApp}
       className="fixed bottom-10 right-10 bg-green-500 hover:bg-green-600 text-white p-4 rounded-full shadow-lg z-50"
     >
       <FaWhatsapp className="w-6 h-6" />
     </button>
 
 
     
 {/*content area  */}
 <div className="flex justify-center w-full mx-auto md:mx-auto lg:mt-12">
        <ul className="flex flex-wrap justify-center gap-3 mx-1">
          <li className={`my-4 hover:backdrop-blur-md p-1  sm:px-2 md:px-4 px-5  rounded-full text-md font-bold py-2 ${activeTab === "oneDay" ? "bg-[#1d76bf] text-white" : "bg-slate-200"}`}>
            <Link to="/booknow" onClick={() => toggleTab("oneDay")} className={`${activeTab === "oneDay" ?"text-white":"text-[#1d76bf]"}`}>One Day Package</Link>
          </li>
          <li className={`my-4 hover:backdrop-blur-md p-1 px-5 sm:px-2 md:px-4 lg:px-5 xl:px-6  rounded-full text-md font-bold py-2 ${activeTab === "twoDay" ? "bg-[#1d76bf] text-white" : "bg-slate-200"}`}>
            <Link to="/TwoDayPackage" onClick={() => toggleTab("twoDay")} className={`${activeTab === "twoDay" ?"text-white":"text-[#1d76bf]"}`}>Two Day Package</Link>
          </li>
          <li className={`my-4 hover:backdrop-blur-md p-1 px-5 sm:px-2 md:px-4 lg:px-5 xl:px-6  rounded-full text-md font-bold py-2 ${activeTab === "threeDay" ? "bg-[#1d76bf] text-white" : "bg-slate-200"}`}>
            <Link to="/ThreeDayPackage" onClick={() => toggleTab("threeDay")} className={`${activeTab === "threeDay" ?"text-white":"text-[#1d76bf]"}`}>Three Day Package</Link>
          </li>
        </ul>
      </div>
 
 <div className='overflow-y-scroll scrollbar h-screen scrollbar'>


 <Routes>
          <Route path="/booknow" element={<OneDayPackage />} />
          <Route path="/TwoDayPackage" element={<TwoDayPackage />} />
          <Route path="/ThreeDayPackage" element={<ThreeDayPackage />} />
          <Route path="/house-boat" element={<HouseBoat />} />
          <Route path="/himachal-tourism" element={<HimachalTourism />} />
          <Route path="/north-kerala" element={<Northkerala />} />
          <Route path="/ladakh" element={<Ladakh />} />
          <Route path="/delhi-agra" element={<DelhiAgra />} />
          <Route path="/goa" element={<Goa />} />
          <Route path="/international" element={<International />} />
        </Routes>
 </div>
 
 <Footer/>
 </Router>
    </div>
    


  );
}

export default App;
