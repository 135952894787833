import React, { useState, useEffect } from 'react';

const Counter = ({ stopValue }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count >= stopValue) {
      return; // Stop the counter if count reaches the stopValue
    }

    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 100); // Update every 10 milliseconds

    return () => {
      clearInterval(interval);
    };
  }, [count, stopValue]); // Run whenever count or stopValue changes

  return (
    <div className="text-center xl:text-6xl lg:text-4xl sm:text-4xl md:text-4xl text-[35px] text-[#1d76bf] font-bold mx-4">
      {count}+
    </div>
  );
};

export default Counter;
