import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slideone from '../assets/slide-1.jpg';
import slidetwo from '../assets/slide-2.jpg';
import slidethree from '../assets/slide-3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const BannerSlider = () => {
  const settings = {
    autoplay: true, // Autoplay enabled
    autoplaySpeed: 6000, // Slide changes every 5 seconds
    speed:2000, // Slide animation speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    cssEase: 'fade', // Animation easing type
    arrows: true, // Show navigation arrows
    pauseOnHover: false, // Pause autoplay on hover
    // pauseOnFocus: true, // Pause autoplay on focus
  };

// whatsap function code
const openWhatsApp = () => {
  // Phone number with country code
  const phoneNumber = '9746027227';
  // Message to be sent
  const message = 'Hello, I would like to chat with you!';
  // Encode message for URL
  const encodedMessage = encodeURIComponent(message);
  // WhatsApp API link
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  // Open link in new tab
  window.open(whatsappLink, '_blank');
};
// end whatsap function code 
  

  return (
    <Slider {...settings}>
<div className="relative">
  
  <div className=" h-[600px] flex justify-center items-center">
    <div className="absolute inset-0 bg-cover bg-center bg-opacity-50 backdrop-filter backdrop-blur-lg">
    <div className="bg-black opacity-50 absolute inset-0 z-0 overflow-hidden"></div> {/* Black overlay */}
    <img src={slideone} alt="Slide 1 background" className="w-full h-full object-cover" />
    <video autoPlay muted loop className="absolute inset-0 w-full h-full object-cover">
        <source src="your_video.mp4" type="video/mp4/mov" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div className="relative z-10 text-white lg:w-[80%] w-full">
      <div className='float-left lg:w-[50%] w-full p-10 pl-4'>
        
      <h1 className="text-4xl lg:text-5xl p-2 font-bold">Adventure Awaits!</h1>
      <p className='p-2'>Set free your inner explorer and join us on epic journeys to find new horizons in God’s own Country.</p>
      <div className='flex gap-5 p-2 mt-5'>
      <button onClick={openWhatsApp} className="text-black font-md text-lg bg-white px-4 py-2 rounded-lg">
      <span className='px-2'>Get in touch with us</span>
      </button>
        {/* <button className='text-white font-semibold text-md'>Read more</button> */}
      </div>
      </div>
     
    </div>
  </div>
</div>




<div className="relative">
  <div className=" h-[600px] flex justify-center items-center">
    <div className="absolute inset-0 bg-cover bg-center bg-opacity-50 backdrop-filter backdrop-blur-lg">
      <img src={slidetwo} alt="Slide 1 background" className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Black overlay */}
    </div>
    <div className="relative z-10 text-white lg:w-[80%] w-full">
      <div className='float-left lg:w-[50%] w-full p-10 pl-4'>
      <h1 className="text-4xl lg:text-5xl p-2 font-bold">Witness Cultural Marvels</h1>
      <p className='p-2'>Immerse yourself in the heart of diverse cultures and create unforgettable memories with us.</p>
      <div className='flex gap-5 p-2 mt-5'>
      <button onClick={openWhatsApp} className="text-black font-md text-lg bg-white px-4 py-2 rounded-lg">
      <span className='px-2'>Get in touch with us</span>
      </button>
      </div>
      </div>
     
    </div>
  </div>
</div>

<div className="relative">
  <div className=" h-[600px] flex justify-center items-center">
    <div className="absolute inset-0 bg-cover bg-center bg-opacity-50 backdrop-filter backdrop-blur-lg">
      <img src={slidethree} alt="Slide 1 background" className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Black overlay */}
    </div>
    <div className="relative z-10 text-white lg:w-[80%] w-full">
      <div className='float-left lg:w-[50%] w-full p-10 pl-4'>
      <h1 className="text-4xl lg:text-5xl p-2 font-bold">Discover Serene Getaways</h1>
      <p className='p-2'>Explore peaceful landscapes and enjoy the beauty of nature with our unique travel experiences.</p>
      <div className='flex gap-5 p-2 mt-5'>
      <button onClick={openWhatsApp} className="text-black font-md text-lg bg-white px-4 py-2 rounded-lg">
      <span className='px-2'>Get in touch with us</span>
    </button>
      </div>
      </div>
     
    </div>
  </div>
</div>









    </Slider>
  );
};

export default BannerSlider;
