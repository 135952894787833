import React from 'react';
import logo from '../assets/logo2.png';
import { faEnvelope, faPhoneAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faTwitterSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paper from '../assets/white-paper.jpg';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import xlogo from '../assets/xlogo.png'
function Footer() {
  const openWhatsApp = () => {
    const phoneNumber = '9746027227';
    const message = 'Hello, I would like to chat with you!';
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className='md:py-20 py-10 w-full p-4 lg:bg-gradient-to-r from-blue-500 to-white/30 bg-gradient-to-b from-blue-500 to-white/30 backdrop-blur-md pt-5'>
      <div className='container mx-auto items-top gap-8 lg:flex lg:justify-center lg:w-[90%]'>
        {/* first section */}
        <div className='text-center lg:text-left mb-8 lg:mb-0'>
          <img src={logo} alt='logo' className='mx-auto lg:mx-0 mb-4 lg:mb-0 w-40' /> <br/>
          <p className='text-[#012e7b] text-lg font-semibold'>Estuaries Holidays offers inbound, domestic <br/> and outbound tours both locally and internationally. <br/>To get the greatest travel experiences,<br/> <b className='cursor-pointer' onClick={openWhatsApp}>connect with us</b></p>
        </div>
        {/* second section */}
    {/* <div className='mb-8 lg:mb-0 text-center'>
  <h1 className='text-lg font-semibold mb-2 text-[#012e7b] text-left'>Find us</h1>
  <ul className='text-sm text-black space-y-2'>
    <li className="flex items-center py-1">
      <FontAwesomeIcon icon={faEnvelope} className="text-[#012e7b] mr-2" />
      <a href='mailto:info@estuariesholidays.com' target="_blank" rel="noopener noreferrer" className="text-[#012e7b] font-semibold text-lg hover:underline">info@estuariesholidays.com</a>
    </li>
    <li className="flex items-center py-1">
      <FontAwesomeIcon icon={faPhoneAlt} className="text-[#012e7b]  mr-2" />
      <a href='tel:7994627227' className="text-[#012e7b] font-semibold text-lg hover:underline">+91 7994627227</a>
    </li>
    <li className="flex items-center py-1">
      <FontAwesomeIcon icon={faPhoneAlt} className="text-[#012e7b] mr-2" />
      <a href='tel:8111972277' className="text-[#012e7b] font-semibold text-lg hover:underline">+91 8111972277</a>
    </li>
    <li className="flex items-center py-1">
      <FontAwesomeIcon icon={faGlobe} className="text-[#012e7b] mr-2" />
      <a href='https://www.estuariesholidays.com/' target="_blank" rel="noopener noreferrer" className="text-[#012e7b] font-semibold text-lg hover:underline">www.estuariesholidays.com</a>
    </li>
  </ul>
</div> */}
<div>
<h1 className='text-lg font-semibold mb-2 text-[#012e7b] md:text-left text-center'>Find us</h1>
  <div className='md:text-left text-center'>
  <li className="list-none lg:flex items-center py-1">
      <FontAwesomeIcon icon={faEnvelope} className="text-[#012e7b] mr-2" />
      <a href='mailto:info@estuariesholidays.com' target="_blank" rel="noopener noreferrer" className="text-[#012e7b] font-semibold text-lg hover:underline">info@estuariesholidays.com</a>
  </li>
  <li className="list-none lg:flex items-center py-1">
      <FontAwesomeIcon icon={faPhoneAlt} className="text-[#012e7b]  mr-2" />
      <a href='tel:7994627227' className="text-[#012e7b] font-semibold text-lg hover:underline">+91 7994627227</a>
    </li>
  <li className="list-none lg:flex items-center py-1">
      <FontAwesomeIcon icon={faPhoneAlt} className="text-[#012e7b] mr-2" />
      <a href='tel:8111972277' className="text-[#012e7b] font-semibold text-lg hover:underline">+91 8111972277</a>
  </li>
  <li className="list-none lg:flex items-center py-1">
      <FontAwesomeIcon icon={faGlobe} className="text-[#012e7b] mr-2" />
      <a href='https://www.estuariesholidays.com/' target="_blank" rel="noopener noreferrer" className="text-[#012e7b] font-semibold text-lg hover:underline">www.estuariesholidays.com</a>
    </li>
  </div>
</div>

        {/* third section */}
        <div className='text-center lg:text-left mt-2 md:mt-0'>
          <h1 className='text-lg font-semibold mb-2 text-[#012e7b]'>Contact us</h1>
          <p className='text-lg font-semibold text-[#012e7b]'>National Residency<br/>
Shopping Complex , Edapally , Cochin-24<br/>
Kerala,India</p>
          {/* Social media icons */}
          <div className="flex justify-center lg:justify-start my-4">
  <a href='https://www.facebook.com/profile.php?id=100083327257942' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} className="text-[#012e7b] text-3xl mx-2" /></a>
  <a href='https://twitter.com/i/flow/login?redirect_after_login=%2FEstuariesH' target="_blank" rel="noopener noreferrer">
    {/* <FontAwesomeIcon icon={faTwitterSquare} className="text-[#1da1f2] text-3xl mx-2" /> */}
    <img src={xlogo} className='w-14 mt-[-6px]' />
    
    </a>
  <a href='https://www.instagram.com/estuaries_holidays/?igshid=YmMyMTA2M2Y%3D' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className="text-[#c32aa3] text-3xl mx-2" /></a>
</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
