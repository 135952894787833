import { FaTimes } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { Audio } from 'react-loader-spinner';
import Swal from 'sweetalert2';

function TwoDayPackage() {
  const [showPopup, setShowPopup] = useState(false);
  const [showcontentPopup, setShowContentPopup] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [selectedContent, setSelectedContent] = useState('');
  const [packageprice, setPackagePrice] = useState();
  const [loader, setLoader] = useState(false);
  const [slideIndexes, setSlideIndexes] = useState({});
  const [packages, setPackages] = useState([]);
  const [autoSlideIntervalIds, setAutoSlideIntervalIds] = useState({});
  const [totalPrice,setTotalPrice] = useState()
  const [formError, setFormError] = useState('');
  const [totalDays, setTotalDays] = useState(0);
  const [formData, setFormData] = useState({
    name: '', 
    email: '',
    message: '',
    // packageName: '',
    contactNumber: '',
    totalPrice:'',
    // adults: '',
    // children: '',
    // pickupDate: '',
    selectedPriceOption: [], // Initialize selectedPriceOption as an empty array
    checkInDate: '',
    checkOutDate: '',
    totaldays:'',
  });



// Data saving function
const HandleClientInfo = async (paymentID)=>{
  try {
    // Update total days before making the API call
    handleDateSelection();
  
    const formDataWithTotalPrice = {
      ...formData,totalPrice: totalPrice,
      
      ...formData,totaldays: totalDays, // Update this line to use totalDays state instead of formData.totaldays
      ...formData,paymentId:paymentID,
    };
  
    const response = await fetch('https://cttapi.jinskadamthodu.com/estuariesholidays/api/v1/save_data.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formDataWithTotalPrice)
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    setFormData({
      name: '',
      email: '',
      message: '',
      contactNumber: '',
      selectedPriceOption: [],
      checkInDate: '',
      checkOutDate: '',
      totaldays: ''
    });
  
    setShowPopup(false);
  } catch (error) {
    console.error('Error submitting form:', error);
    Swal.fire(error);
  }
}
// end data saving function












  useEffect(() => {
    const initialIndexes = {};
    packages.forEach((pkg, index) => {
      initialIndexes[pkg.pkg_name] = 0;
    });
    setSlideIndexes(initialIndexes);
  }, [packages]);

  const handleNext = (pkgName) => {
    setSlideIndexes((prevIndexes) => ({
      ...prevIndexes,
      [pkgName]: (prevIndexes[pkgName] + 1) % 3,
    }));
  };

  const handlePrev = (pkgName) => {
    setSlideIndexes((prevIndexes) => ({
      ...prevIndexes,
      [pkgName]: prevIndexes[pkgName] === 0 ? 2 : prevIndexes[pkgName] - 1,
    }));
  };

  const startAutoSlide = (pkgName) => {
    const intervalId = setInterval(() => {
      handleNext(pkgName);
    }, 5000);
    setAutoSlideIntervalIds((prevIds) => ({
      ...prevIds,
      [pkgName]: intervalId,
    }));
  };

  useEffect(() => {
    packages.forEach((pkg) => {
      startAutoSlide(pkg.pkg_name);
    });
    
    return () => {
      Object.values(autoSlideIntervalIds).forEach((intervalId) => {
        clearInterval(intervalId);
      });
    };
  }, [packages]);

  const openWhatsApp = () => {
    const phoneNumber = '9746027227';
    const message = 'Hello, I would like to chat with you!';
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, '_blank');
  };

  const options = {
    key: 'rzp_live_37ULTth4tlev6A',
    amount: totalPrice * 100,
    
    name: 'Estuaries Holydays',
    description: 'some description',
    image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
    handler: function (response) {
      // alert(response.razorpay_payment_id, '=razorpay_payment_id');
      const paymentID = response.razorpay_payment_id;
      HandleClientInfo(paymentID)
      closepopUp()
      Swal.fire('we get in Touch with you soon');
    },
    prefill: {
      name: formData.name,
      contact: formData.contactNumber,
      email: formData.email
    },
    notes: {
      message: formData.message
    },
    theme: {
      color: 'blue',
      hide_topbar: false
    }
  };

  const openPayModal = () => {
    var rzp1 = new window.Razorpay(options); 
    rzp1.open();
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    const fetchData = async () => {
      try {
        const response = await fetch('https://cttapi.jinskadamthodu.com/estuariesholidays/api/v1/view.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const southKeralaData = data.filter(item => item.main_pkg === "TWO DAY PACKAGES");
        setPackages(southKeralaData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleDateSelection()
    // Check if at least one price option is selected
    if (formData.selectedPriceOption.length === 0) {
      setFormError('Please select at least one price option');
      return; // Prevent form submission
    }
  
    // Clear form error if previously set
    setFormError('');
  
    // Your existing form submission logic
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const closepopUp = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
      packageName: '',
      contactNumber: '',
      adults: '',
      children: '',
      pickupDate: '',
      selectedPriceOption: [] // Initialize selectedPriceOption as an empty array
    });
    setShowPopup(false);
  };

  const HandleBooking = (pkg) => {
    setShowPopup(true); 
    setFormData({ ...formData, packageName: pkg.pkg_name }); 
    setPackagePrice(pkg.price);
  
    // Splitting the price options
    const priceOptionsArray = pkg.price.split(/\s(?=\d{1,3},\d{1,3}\s\+\s5%\sGST\sFOR\s(?:\d\s)?PAX+)/);
    console.log(priceOptionsArray);
    // Update state with price options array
    setFormData({ ...formData, priceOptions: priceOptionsArray });
  }


  const handleCheckboxChange = (e, option) => {
    const isChecked = e.target.checked;
    let updatedSelectedOptions = [...formData.selectedPriceOption];
  
    if (isChecked) {
      updatedSelectedOptions.push(option); // Add the selected option to the array
    } else {
      updatedSelectedOptions = updatedSelectedOptions.filter(item => item !== option); // Remove the deselected option from the array
    }
  
    setFormData({
      ...formData,
      selectedPriceOption: updatedSelectedOptions
    });
  
    // Calculate total price based on selected options
    let totalPrice = 0;
    updatedSelectedOptions.forEach(selectedOption => {
      const parts = selectedOption.split('+'); // Split the option into price and GST parts
      const price = parseFloat(parts[0].replace(/[^0-9.]/g, '')); // Extract numeric part of the price and remove non-numeric characters
      const gstString = parts[1].trim(); // Trim any leading or trailing whitespace
      const gstPercentage = parseFloat(gstString.slice(0, -1)); // Extract numeric part of the GST and remove the % sign
      const gstAmount = (price * gstPercentage) / 100; // Calculate GST amount
      const totalPriceWithGST = price + gstAmount; // Calculate total price with GST
      totalPrice += totalPriceWithGST; // Add total price with GST to total
      console.log("Total Price with GST:", totalPriceWithGST);
    });
  
    // Update total price
    const finalPrice = totalPrice.toFixed(2);
    setTotalPrice(finalPrice);
    console.log("Total Price with GST:", finalPrice);
  };
  
  

const handleDateSelection = () => {
  const { checkInDate, checkOutDate } = formData;
  if (checkInDate && checkOutDate) {
    const startDate = new Date(checkInDate);
    const endDate = new Date(checkOutDate);
    const timeDifference = endDate.getTime() - startDate.getTime();
    const totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
    setTotalDays(totalDays);
    console.log('Total number of days:', totalDays);
    
  }
};






  return (
    <div>
      {loader == true ? <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
        <div className="flex items-center justify-center bg-white shadow-lg rounded-full h-20 w-20">
          <Audio
            height="45"
            width="45"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      </div> : ''}
      <div className="flex justify-center items-center my-2 lg:my-10 w-full mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-4 gap-4 w-full max-w-[100%] xl:max-w-[70%] lg:max-w-[90%] p-10 sm:p-12 md:p-24 lg:p-5">
          {packages.map((pkg, index) => (
            <div key={index} className="bg-white shadow-md overflow-hidden hover:shadow-lg transition duration-300 transform hover:-translate-y-1 hover:scale-105 rounded-lg">
              <div className="relative">
                <button className="absolute top-0 bottom-0 left-0 w-5 bg-gray-600 text-white opacity-50 hover:opacity-75 focus:outline-none" onClick={() => handlePrev(pkg.pkg_name)}>&lt;</button>
                <button className="absolute top-0 bottom-0 right-0 w-5 bg-gray-600 text-white opacity-50 hover:opacity-75 focus:outline-none" onClick={() => handleNext(pkg.pkg_name)}>&gt;</button>
                <div className="flex">
                  <img src={pkg.image_url} alt={`Image for ${pkg.pkg_name}`} className={`w-full object-cover ${slideIndexes[pkg.pkg_name] === 0 ? 'block' : 'hidden'} `} />
                  <img src={pkg.image_url_1} alt={`Image for ${pkg.pkg_name}`} className={`w-full object-cover ${slideIndexes[pkg.pkg_name] === 1 ? 'block' : 'hidden'}`} />
                  <img src={pkg.image_url_2} alt={`Image for ${pkg.pkg_name}`} className={`w-full object-cover ${slideIndexes[pkg.pkg_name] === 2 ? 'block' : 'hidden'}`} />
                </div>
              </div>
              <div className="p-4">
                <p className="text-md font-bold mb-2 text-[#1d76bf]">{pkg.pkg_name}</p>
                <div className='flex justify-between items-center'>
                  <p className="text-[#1d76bfb4] text-sm font-semibold">{pkg.main_pkg}</p>
                  <button className="text-white mt-2 bg-[#1d76bfb4] p-1 rounded-sm font-semibold text-sm" onClick={() => { setSelectedPackage(pkg.pkg_name); setShowContentPopup(true); setSelectedContent(pkg.pkg_description) }}>Know more</button>
                </div>
                <div className='overflow-y-scroll h-14 my-2 px-2 border-[1px] rounded-sm border-[#1d76bfb4] scrollbar'>
                  <p className="mt-2 text-md font-bold text-[#1d76bf]">₹{pkg.price}</p>
                </div>
                <button className="text-white mt-2 bg-[#1d76bfb4] p-1 rounded-sm font-semibold text-sm w-full" onClick={()=>{HandleBooking(pkg)}}>Book Now</button>
              </div>
            </div>
          ))}
        </div>

        <div>
          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30">
              <div className="bg-white p-6 rounded shadow-lg w-[90%] md:w-[70%] lg:w-[60%] xl:w-[40%] h-[50%] overflow-x-scroll">
                <div className='flex w-full justify-between'>
                  <h2 className="text-lg font-semibold mb-4">Book with us</h2>
                  <span className=' bg-slate-400 p-1 h-6 rounded-full mt-1 cursor-pointer hover:animate-spin' onClick={closepopUp}><FaTimes /></span>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                  <label className="block text-[#012e7b] text-sm font-bold mb-2" htmlFor="name">Name</label>
                  <input className="appearance-none rounded-sm border-blue-400 border-b w-full py-2 px-3 text-[#012e7b] leading-tight focus:outline-none focus:shadow-outline" type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                    <div className="mt-2">
                    <label className="block text-[#012e7b] text-sm font-bold mb-2" htmlFor="name">Choose package</label>
  {/* Display checkboxes for price options */}
  {formData.priceOptions && formData.priceOptions.map((option, index) => (
    <div key={index} className="flex items-center">
      <input 
        type="checkbox" 
        id={`option-${index}`} 
        name={`selectedPriceOption-${index}`} 
        value={option} 
        checked={formData.selectedPriceOption.includes(option)}
        onChange={(e) => handleCheckboxChange(e, option)}
      />
      <label htmlFor={`option-${index}`} className="ml-2 text-[#012e7b] font-bold">{option}</label>
    </div>
  ))}
</div>
{formError && <p className="text-red-500">{formError}</p>}
                  </div>
                  {/* Rest of the form */}
                  <div className="mb-4">
                <label className="block text-[#012e7b] text-sm font-bold mb-2" htmlFor="email">Email</label>
                <input className="appearance-none rounded-sm border-blue-400 border-b w-full py-2 px-3 text-[#012e7b] leading-tight focus:outline-none focus:shadow-outline" type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
                <label className="block text-[#012e7b] text-sm font-bold mb-2" htmlFor="contactNumber">Contact Number</label>
                <input className="appearance-none rounded-sm border-blue-400 border-b w-full py-2 px-3 text-[#012e7b] leading-tight focus:outline-none focus:shadow-outline" type="tel" id="contactNumber" name="contactNumber" value={formData.contactNumber} onChange={handleInputChange} required />
              </div>
              <div className="mb-4">
              <label className="block text-[#012e7b] text-sm font-bold mb-2" htmlFor="checkInDate">Check-in Date</label>
      <input className="appearance-none rounded-sm border-blue-400 border-b w-full py-2 px-3 text-[#012e7b] leading-tight focus:outline-none focus:shadow-outline" type="date" id="checkInDate" name="checkInDate" value={formData.checkInDate} onChange={handleInputChange} required />

      <label className="block text-[#012e7b] text-sm font-bold mb-2" htmlFor="checkOutDate">Check-out Date</label>
      <input className="appearance-none rounded-sm border-blue-400 border-b w-full py-2 px-3 text-[#012e7b] leading-tight focus:outline-none focus:shadow-outline" type="date" id="checkOutDate" name="checkOutDate" value={formData.checkOutDate} onChange={handleInputChange} required />

   
              </div>
              <div className="mb-4">
                <label className="block text-[#012e7b] text-sm font-bold mb-2" htmlFor="message">Message</label>
                <textarea className="appearance-none rounded-sm border-blue-400 border-b w-full py-2 px-3 text-[#012e7b] leading-tight focus:outline-none focus:shadow-outline" id="message" name="message" value={formData.message} onChange={handleInputChange} />
              </div>
              <div className='flex justify-between items-center gap-2'>
              <button type="submit" className="bg-[#012e7b] text-white font-bold py-2 px-4 rounded w-full">Submit</button>
              <button onClick={closepopUp} className="bg-blue-400 text-white font-bold py-2 px-4 rounded w-full">close</button>
              </div>
                  {/* <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button> */}
                </form>
              </div>
            </div>
          )}
        </div>

        {showcontentPopup ? <div>
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-30">
            <div className="bg-white rounded shadow-lg w-[90%] md:w-[70%] lg:w-[70%] xl:w-[70%] h-[50%] overflow-x-scroll">
              <div className='flex justify-between h-16 w-full items-center p-4 border-b-2 border-[#012e7b]'>
                {selectedPackage && <span className='text-[#012e7b] font-semibold text-2xl'>{selectedPackage}</span>}
                <span className='cursor-pointer text-[#012e7b] text-lg' onClick={() => { setShowContentPopup(false) }}><FaTimes /></span>
              </div>
              <div className='p-4 text-justify text-black text-2xl'>
                <div className='p-4 text-justify text-black text-2xl' dangerouslySetInnerHTML={{ __html: selectedContent }}></div>
              </div>
              <div className='p-4 flex items-center justify-between bottom-5 w-full gap-5'>
                <div className='w-full'>
                  <button onClick={openWhatsApp} className="whatsapp-button bg-green-800 rounded-lg w-full py-1 text-white">
                    <span className='px-2 text-sm font-semibold'>WhatsApp</span>
                  </button>
                </div>
                <div className='w-full'>
                  <button onClick={() => { setShowContentPopup(false) }} className="whatsapp-button bg-red-800 rounded-lg w-full py-1 text-white">
                    <span className='px-2 text-sm font-semibold'>close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> : ''}
      </div>
    </div>
  );
}
export default TwoDayPackage;
